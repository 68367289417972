import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.product', route: { name: 'product' } },
      ],
      listRoute: () => ({ name: 'product' }),
      afterCreateRoute: result => ({
        name: 'product-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product',
      defaultData: this._defaultData,
      metaAction: {
        preview: {
          label: 'action.view-in-frontend',
          color: 'green',
          buttonProperties: () => ({ class: 'white--text' }),
          href: (formInfo: FormInfoInterface) => {
            const formData = formInfo.formData
            if(!formData) return null
            // @ts-ignore
            return `${this.vm.frontendDomain}/product-detail/${formData.code}`
          },
          linkTarget: '_blank',
        },
      },
      blocks: [
        {
          data: {
            banner_id: {
              label: 'data.banner',
              type: 'banner',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
              disabledFullscreenOption: true,
              recommendSize: {
                width: 1500,
                height: 300,
              },
            },
          },
        },

        {
          title: 'form_block.base',
          data: {
            categories: {
              label: 'data.categories',
              type: 'selection',
              optionMeta: item => ({ depth: item.depth }),
              // @ts-ignore
              itemDisabled: (item, formMeta) => item.depth != this.vm.productCategoryMaxDepth,
              optionText: (itemName, item: AnyObject) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
              refreshCallback: async (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return
                const result = await formInfo.formMeta.loadCategories()
                this.vm?.$store.commit(`form/${this.formKey}/setMetaProperty`, {
                  key: 'categories',
                  value: !Array.isArray(result.nodes) ? [] : result.nodes,
                })
              },
              optionTextKey: 'name',
              optionValueKey: 'id',
              multiple: true,
              returnObject: true,
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return []
                if(!Array.isArray(formInfo.formMeta.categories)) return []
                return formInfo.formMeta.categories
              },
              hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return arrayPropertyDiff(originalData, data, 'id')
              },
            },
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              maxlength: 100,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            code: {
              required: true,
              label: 'data.code',
              type: 'text',
              maxlength: 200,
              randomUid: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            keywords: {
              label: 'data.keywords',
              help: 'keyword.help',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photos: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'product',
              photoManager: true,
              multiple: true,
              total: 6,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            brief: {
              label: 'data.brief',
              type: 'textarea',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            content: {
              label: 'data.content',
              type: 'html',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            meta: {
              label: 'product.data.meta',
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./productForm/productMeta.vue'),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
          },
        },

        // 商品主資料設定
        {
          title: 'product.form_block.setup',
          data: {
            // 商品規格
            instance_config: {
              label: 'product.data.spec',
              grid: { xl: 12, lg: 12, md: 12 },
              create: (formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return false
                return !!formInfo.formData.instance_config
              },
              component: () => import('./productForm/instanceConfig.vue'),
            },
            instances: {
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./productForm/instances.vue'),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
          },
        },

        // 發布設定
        {
          title: 'form_block.publish',
          data: {
            status: {
              label: 'data.status',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
              grid: { xl: 12, lg: 12, md: 12},
            },
            publish_start: {
              label: 'product.data.publish_start',
              type: 'time',
            },
            publish_end: {
              label: 'product.data.publish_end',
              type: 'time',
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
