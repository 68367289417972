<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './productFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'product-form',
    meta: {},
  }),
  mounted() {
    this.meta.loadCategories = this.loadCategories
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.loadCategories(),
      ])
    },
    async loadCategories() {
      let result = null
      try {
        result = await this.$api.collection.productCategoryApi.flatTree()
      } catch (error) {
        console.warn(error)
      }

      this.meta.categories = result.nodes
      return result
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi(target) {
      return await this.$api.collection.productApi.read(target)
    },
    async createApi(formData) {
      return await this.$api.collection.productApi.create(formData)
    },
    async updateApi(target, formData) {
      return await this.$api.collection.productApi.update(target, formData)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(target)
    },
    getFormConfig() {
      return formConfig
    },
  },
  computed: {
    frontendDomain() {
      return this.$store.getters['base/eagleEnv'].frontendDomain
    },
    productCategoryMaxDepth() {
      return this.$store.getters['base/productCategoryMaxDepth']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
